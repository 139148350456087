/* @import "./soft-ui-dashboard.css"; */


::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #A8B8D8;
}

::-webkit-scrollbar {
  width: 10px;
}

/* :root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
} */
.App {
  
}
@font-face {
  font-family: "din-next";
  src: url("./fonts/DINNextLTPro-Bold.ttf") format('truetype');
}

body {
  font-weight: 400;
  line-height: 1.6;
  background-color: #404040;
  color: #fff;
  font-family: din-next !important;
}

p {
  line-height: 1.625;
  font-weight: 400;
  font-size: 1rem;
}

.title{
  text-align: center;
  padding-top: 20px;
  font-size: 80px;
}

@media (max-width:1000px){
  .title {
    font-size: 50px;
  }
}

/* .card{
  
  text-align: left;
  max-width: 800px;
} */

.container{
  margin: auto;
  width: 100%;
  min-width: 380px;
}

.endpoint{

    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #333333;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 30px;
    /* margin-left: 30px; */
}

.endpoint-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.endpoint .endpoint-body{
  padding: 1.5rem;
}

.codemirror {
  background-color: #404040;
  border: 0;
  border-left: 2px solid #A8B8D8;
  color: #dedede;
  font-family: din-next;
  height: -moz-fit-content;
  height: fit-content;
  line-height: 1;
  overflow: auto;
  max-height: 250px;
  padding: 0em 1rem;
  white-space: pre;
  font-weight: bold;
}

.codemirror pre {
  border-radius: 0;
  border-width: 0;
  background: transparent;
  margin: 0;
  white-space: pre;
  color: #fff;
  position: relative;
  overflow: visible;
}

.badge {
  display: inline-block;
  padding: 0.55em 0.9em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.45rem;
  text-transform: uppercase;
}

.bg-gradient-info {
  background-image: linear-gradient(
310deg
, #2152ff 0%, #21d4fd 100%);
}
.btn {
  display: inline-block;
  font-weight: 700;
  line-height: 1.4;
  color: #67748e;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 0.5rem;
  transition: all 0.15s ease-in;
}
.btn {
  margin-bottom: 1rem;
  letter-spacing: -0.025rem;
  text-transform: uppercase;
  box-shadow: 0 4px 7px -1px rgb(0 0 0 / 11%), 0 2px 4px -1px rgb(0 0 0 / 7%);
  background-size: 150%;
  background-position-x: 25%;
}


button:hover, button:focus, button:active {
  outline: none;
  color: #28a745;
}

.bg-gradient-secondary {
  background-image: linear-gradient(
310deg
, #627594 0%, #A8B8D8 100%);
}
.btn-secondary, .btn.bg-gradient-secondary {
  color: #fff;
}
.btn:focus{
  transform: scale(1.02);
  outline: none;
  box-shadow: none;
  border: none;
}

.btn:hover{
  transform: scale(1.02);
  /* color: #20c997; */
}
label, .form-label {
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #fff;
  margin-left: 0.25rem;
}


.form-control {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  color: #fff;
  background-color: #495057;
  background-clip: padding-box;
  border: 1px solid #d2d6da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.5rem;
  transition: box-shadow 0.15s ease, border-color 0.15s ease;
}

.form-control:focus {
  color: #fff;
  background-color: #495057;
  border-color: #fff;
  outline: 0;
  box-shadow: 0 0 0 1px rgb(255, 255, 255);
}

.tooltip{
  font-family: din-next !important;
}